import React from 'react';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ContainerPanel from '@mp/mp-container-panel';
import BtnIconTooltip from '@mp/mp-btn-icon-tooltip';
import LoadingMask from '@mp/mp-loading-mask';
import {LinkToolbar} from '@mp/mp-link-toolbar';
import { BrowserRouter as Router, withRouter } from "react-router-dom";

const Page = props => {
    
    const [hidden, setHidden] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);

    const onClickDisabled = (value) => {
        setDisabled(value)
    }

    const onClickHidden = (value) => {
        setHidden(value)
    }

    return (
        <span>     
            <LinkToolbar 
                {...props} 
                basename={props.basename}
                itemsButtons={props.itemsButtons}
            />       
            <ContainerPanel> 
                
                <BtnIconTooltip tooltip='prueba' icon={<AssessmentIcon/>} onClick={() => onClickDisabled(!disabled)}/>  
                <BtnIconTooltip tooltip='prueba' icon={<AssessmentIcon/>} onClick={() => onClickHidden(!hidden)}/>  
                <BtnIconTooltip disabled={disabled} tooltip='prueba' icon={<AssessmentIcon/>}/>  
                <BtnIconTooltip hidden={hidden} tooltip='prueba' icon={<AssessmentIcon/>}/>  
                <LoadingMask open={hidden}/>


                {props.name} is mounted! fff
            </ContainerPanel>
        </span> 
    )
}

export default withRouter(Page);