import React from 'react';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ExplicitIcon from '@mui/icons-material/Explicit';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { BrowserRouter as Router} from "react-router-dom";
import Page from './components/Page';

const panelsItems = [
    {
        label: 'Dashboard'
        ,path: '/dashboard'
        ,lazyModule: null
        ,icon: <PersonPinIcon/>
        ,disabled: false
        ,hidden: false
    }
    ,{
        label: 'Panels'
        ,path: '/panels'
        ,lazyModule: null
        ,icon: <ExplicitIcon/>
        ,disabled: false
        ,hidden: false
    }
    ,{
        label: 'Dashboard'
        ,path: '/dashboard1'
        ,lazyModule: null
        ,icon: <PersonPinIcon/>
        ,disabled: false
        ,hidden: true
    }
    ,{
        label: 'Panels'
        ,path: '/panels1'
        ,lazyModule: null
        ,icon: <ExplicitIcon/>
        ,disabled: true
        ,hidden: false
    }
    ,{
        label: 'Accion 1'
        ,path: null
        ,lazyModule: null
        ,icon: <AssessmentIcon/>
        ,disabled: false
        ,hidden: false
        ,onClick: (btn) => {
            console.log(btn)
        }
    }
    ,{
        label: 'Accion 2'
        ,path: null
        ,lazyModule: null
        ,icon: <AssessmentIcon/>
        ,disabled: true
        ,hidden: false
        ,onClick: (btn) => {
            console.log(btn)
        }
    }
];

const Root = props => {

    return (
        <Router>
            <Page basename={props.basename} itemsButtons={panelsItems}/>
        </Router>
    )
}

export default Root;